import { ProjectSettings, ProjectSlug } from "@/settings/types";

export const bora: ProjectSettings = {
  name: "Bora",
  description: "最靚價錢，最大效果，「絕不硬銷、用心服務」",
  iconUrl: "/images/icon/project-icon-bora.png",
  avatarUrl: "/images/icon/project-avatar-bora.png",
  slug: ProjectSlug.Bora,
  graphqlEndpoint: {
    production: "https://bora.hk/api/graphql",
    preview: "https://preview.bora.hk/api/graphql",
    // please edit your local `/etc/hosts` file to enable local env
    local: "http://localhost:3038/api/graphql",
  },
  webEndpoint: {
    production: "https://bora.hk",
    preview: "https://preview.bora.hk",
    local: "http://localhost:3038",
  },
  cmsBasePathRegex: {
    production: "cms.bora.hk",
    preview: "cms-preview.bora.hk",
    local: "boracms",
  },

  allowEmailRegex: {
    production:
      "jacky@wemakeapp.net|samuel@wemakeapp.net|csanwel@gmail.com|ytl1017work@gmail.com|workkaroly@gmail.com|admin@bora.hk|chankatrina434@gmail.com|kellyworks2023@gmail.com|dirui@bora.hk",
    preview: "@wemakeapp.net|csanwel@gmail.com|test@wma.net",
    local: "@wemakeapp.net|csanwel@gmail.com",
  },
  types: {
    CmsArticle: {
      displayField: "title",
    },
    CmsNavItem: {
      displayField: "title",
    },
    CmsTreatment: {
      displayField: "title",
    },
    CmsShop: {
      displayField: "title",
    },
    LinkContent: {
      displayField: "url",
    },
  },
  cloudinaryConfig: {
    cloudName: "dpaqr0jkt",
    uploadPreset: "dawpmzdg",
  },
  reactPageColorPalette: [
    {
      title: "Main",
      colors: ["#D2AC3D", "#836304", "#ECCE75", "#202020", "#F2F2F2"],
    },
    {
      title: "Grey",
      colors: ["#333333", "#4F4F4F", "#828282", "#BDBDBD", "#E0E0E0"],
    },
  ],
  sidebarCollections: [
    {
      name: "Navigation",
      url: "/articleNav",
    },
    {
      entityName: "Article", //this will help filter out dynamic collection
      name: "Articles",
      url: "/articles",
    },
  ],
};
